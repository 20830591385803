<template>
    <div>
        <v-card class="ma-3">
            <v-row>
                <v-col cols="12" md="3">
                    <v-card>
                        <v-toolbar dense flat>
                            <div class="custom-toolbar-title">
                                Channels
                            </div>
                        </v-toolbar>
                        <v-navigation-drawer floating permanent>
                            <v-list dense rounded>
                                <v-list-item
                                        :key="item.title"
                                        v-for="item in backJobMenus"
                                        link
                                        @click="onClickedJobs(item)"
                                >
                                    <v-list-item-icon>
                                        <v-icon>{{ item.icon }}</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-navigation-drawer>
                    </v-card>
                </v-col>
                <v-col cols="12" md="9">
                    <v-card>
                        <v-toolbar dense flat>
                            <div class="custom-toolbar-title">
                                Notifications: {{currentJobs.title}}
                            </div>
                        </v-toolbar>
                        <div class="notifications" v-chat-scroll="{smooth: true}">
                            <ul class="notification">
                                <li class="message" v-for="(item, key) in items" :key="key">
                                    {{ item.message }}
                                </li>
                            </ul>
                        </div>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
    import PageTitle from "../components/PageTitle";

    export default {
        name: "BackgroundJobs",
        beforeRouteLeave(to, from, next) {
          this.disconnect();
          next();
        },
        components: {
            PageTitle
        },
        data() {
            return {
                items: [],
                backJobMenus: [
                    {
                        title: 'Broken Links',
                        icon: 'mdi-view-dashboard',
                        channel: 'private-broken-link-channel',
                        event: 'broken-link-notification-event'
                    },
                    {
                        title: 'Invoice Mails',
                        icon: 'mdi-card-account-mail',
                        channel: 'private-invoice-mail-channel',
                        event: 'invoice-mail-notification-event'
                    },
                    {
                        title: 'Bulk Mails',
                        icon: 'mdi-email-newsletter',
                        channel: 'news-letter-notification-channel',
                        event: 'NewsLetterNotificationEvent'
                    },
                    {
                        title: 'Import Mails',
                        icon: 'mdi-progress-upload',
                        channel: 'mail-csv-notification-channel',
                        event: 'MailCsvNotificationEvent'
                    },
                ],
                currentJobs: {
                    title: 'Broken Links',
                    channel: 'private-broken-link-channel',
                    event: 'broken-link-notification-event'
                }
            }
        },
        mounted() {
            this.connect();
        },
        methods: {
            onClickedJobs(item) {
              const {title, channel, event} = item;

              this.disconnect();
              this.currentJobs = {title, channel, event};
              this.connect();
            },
            connect() {
                const {channel, event} = this.currentJobs;
                const me = this;
                const _channel = this.$pusher.subscribe(channel);
                 _channel.bind(event, (data) => {
                    me.items.push(data);
                });
            },
            disconnect() {
                const channel = this.currentJobs.channel;
                this.items = [];
                this.$pusher.unsubscribe(channel);
            }
        }
    }
</script>

<style scoped type="scss">
 .notifications {
     height: 65vh !important;
     overflow-y: scroll;
 }
 .notification {
     list-style: none;
 }

 .notification li{
     border: 1px solid lightblue;
     border-radius: 4px;
     padding: 10px;
     margin-bottom: 15px;
 }

</style>